import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: HttpClient) { }

  /******************/
  /**** SUBASTA ****/
  /****************/

  public getVendedurias(): any {
    let finalUrl = environment.APIURL + '/getVendedurias'
    let headers = new HttpHeaders({ 'content-type': 'application/json ' })// Es la única forma que encontramos de que trague el Authorization
    let httpOptions = { headers: headers };
    return this.http.get<any>(finalUrl, httpOptions);
  }
  public getCesion(id): any {
    let finalUrl = environment.APIURL + '/getCesion/' +  id
    let headers = new HttpHeaders({ 'content-type': 'application/json ' })// Es la única forma que encontramos de que trague el Authorization
    let httpOptions = { headers: headers };
    return this.http.get<any>(finalUrl, httpOptions);
  }
  public insertCesion(data): any {
    let finalUrl = environment.APIURL + '/insertCesion'
    let headers = new HttpHeaders({ 'content-type': 'application/json ' })// Es la única forma que encontramos de que trague el Authorization
    let httpOptions = { headers: headers };
    return this.http.post(finalUrl,data, httpOptions);
  }
  public deleteCesion(data): any {
    let finalUrl = environment.APIURL + '/deleteCesion'
    let headers = new HttpHeaders({ 'content-type': 'application/json ' })// Es la única forma que encontramos de que trague el Authorization
    let httpOptions = { headers: headers };
    return this.http.post(finalUrl,data, httpOptions);
  }
  
  getAuctions(): any {
    var url = environment.APIURL + '/getauctions';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  loadFutureAuctions(number): any {
    var url = environment.APIURL + '/futurasSubastas/' + number;
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  listaParadaPrevia(userId): any {
    var url = environment.APIURL + '/listadoPartidasParadaPrevia/' + userId
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  public getclientes(userId) {
    let url = environment.APIURL + '/clientesAsociados/' + userId
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  public getProveedores() {
    let url = environment.APIURL + '/getProveedores';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  public getEspecies() {
    let url = environment.APIURL + '/getEspecies';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  /*****************/
  /**** FOTOS *****/
  /***************/

  /**
   * Recepción de fotos para las partidas
   * @param part 
   * @returns 
   */
  public getFoto(part): any {
    let finalUrl = environment.APIURL + '/getFoto';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(finalUrl, { "idPart": part }, httpOptions);
  }

  /**
   * Recepción de url en la que se encuentran las fotos de especies por fao. Estas imágenes se usan cuando un lote no tiene asociada una imagen.
   */
  public getFotosUrl() {
    let url = environment.APIURL + '/getFotosUrl';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  /**************************/
  /**** PARADAS PREVIAS ****/
  /************************/

  /**
   * @deprecated
   * Crear, editar paradas
   * @param parada 
   * @returns 
   */
  sendParadaPrevia(parada: any) {
    let url = environment.APIURL + '/addPedidoPrevio';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(url, parada, httpOptions);
  }

  /**
   * @deprecated
   * Eliminar parada
   * @param parada 
   * @returns 
   */
  deleteParadaPrevia(parada: any) {
    let url = environment.APIURL + '/borrarPedidoPrevio';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(url, parada, httpOptions);
  }

  /**
   * @deprecated
   * @param parada Ej: {"cliente":16, "precio":10,"cantidad":10, "id":30363}
   * @returns  
   */
  editParadaPrevia(parada: any) {
    let url = environment.APIURL + '/editarPedido';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(url, parada, httpOptions);
  }

  /**
   * 
   * @param idPartida parámetro opcional para recoger una parada concreta
   * @returns 
   */
  getParadasPrevias(userId, idPartida?) {
    let aux = idPartida ? "/" + idPartida : "";
    let url = environment.APIURL + "/getParadasPrevias/" + userId + aux;
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  /*****************************************************/
  /******************* PARTIDAS *************************/
  /***************************************************/
  getpartidasList(part: any) {
    let url = environment.APIURL + '/getGrupo';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    let body = { "partidas": part };
    return this.http.post<any>(url, body, httpOptions);
  }

  /*************************/
  /**** ADJUDICACIONES ****/
  /***********************/

  public getAdjudicaciones(suffix: any): any {
    var url = environment.APIURL + "/adjudicacionescliente/" + suffix;
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  public getAdjudicacionesFecha(suffix: any, fecha, proveedor, especie): any {
    var url = environment.APIURL + "/adjudicacionescliente/" + suffix + "/" + fecha + "/" + especie + "/" + proveedor;
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }
  public getAdj(fecha?): any {
    var url = environment.APIURL + "/getAdj" 
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let params =new HttpParams().set('fecha', fecha) ;
    let httpOptions = { headers: headers,params:params };
    return this.http.get<any>(url, httpOptions);
  }

  /*************************/
  /**** PUJAS/OFERTAS *****/
  /***********************/

  getListaOfertas(): any {
    var url = environment.APIURL + '/listaOfertas';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(url, httpOptions);
  }

  pujar(params): any {
    var url = environment.APIURL + '/nuevaOferta';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(url, params, httpOptions);
  }

  actualizarPujas(params): any {
    var url = environment.APIURL + '/getOfertasSubasta ';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(url, params, httpOptions);
  }

  notificacionUser(params): any {
    var url = environment.APIURL + '/notificacionUser ';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(url, params, httpOptions);
  }

  /********************/
  /*** VERSION APK ***/
  /******************/

  /**
    * 
    * @DEPRECATED
    */
  getApkVersion() {
    let url = environment.APIURL + '/version';
    return this.http.get<any>(url);
  }

  /**
   * 
   * @DEPRECATED
   */
  getApkRoute() {
    let url = environment.APIURL + '/apk';
    return this.http.get<any>(url);
  }

  getApkStatus() {
    let url = environment.APIURL + '/apk';
    return this.http.get<any>(url);
  }


  /*********************************************/
  /*** CAMBIAR CONTRASENHA USUARIO LOGUEADO ***/
  /*******************************************/

  public cambiarContrasenha(params) {
    let actualPassword = params.actualPassword;
    let newPassword = params.newPassword;
    let newPasswordAgain = params.newPasswordAgain;

    let url = environment.APIURL + '/cambioPassword';
    let headers = new HttpHeaders().append('content-type', 'application/json');
    let httpOptions = { headers: headers };

    return this.http.post<any>(url, { actualPassword: actualPassword, newPassword: newPassword, newPasswordAgain: newPasswordAgain }, httpOptions);
  }

}
