import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG, HAMMER_LOADER } from '@angular/platform-browser';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { IonicModule, } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';

import localeEs from '@angular/common/locales/es';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { MqttService } from './services/mqtt.service'
import { MaterialModule } from './material.module';
import { InterceptorService } from './services/interceptor.service';
import { DirectivesModule } from './directives/directives.module';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { BnNgIdleService } from 'bn-ng-idle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FiltrosComponent } from './modal/filtros/filtros.component';
import { CesionComponent } from './cesion/cesion.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

registerLocaleData(localeEs, 'es');

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  override overrides = {
    'press': { time: 100 }
  }
}


@NgModule({
  declarations: [
    AppComponent,
    FiltrosComponent,CesionComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    DirectivesModule,
    HammerModule,
    FontAwesomeModule,
    NgxCaptchaModule,
  ],
  providers: [
    Insomnia,
    Vibration,
    NativeAudio,
    DecimalPipe,
    IonicStorageModule,
    StatusBar,
    SplashScreen,
    Keyboard,
    MqttService,
    CurrencyPipe,
    Device,
    BnNgIdleService,

    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    { provide: HAMMER_LOADER, useValue: () => new Promise(() => { }) },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
