import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  alertIsPresent: boolean = false;

  constructor(
    private toastCtrl: ToastController,
    public alertCtrl: AlertController,
  ) { }


  /************************************************/
  /******* CONTROL DE FECHA-HORA CABECERA ********/
  /**********************************************/

  realtime = new BehaviorSubject(null);
  interval: any;

  /**
   * Inicio de intervalo de fecha. Se limpia el anterior intervalo, de haberlo.
   */
  startRealtime() {
    try { if (this.interval) { clearInterval(this.interval) } } catch (error) { console.log("eus1"); }
    this.interval = setInterval(() => {
      // console.log("Comprobacion de intervalo de fecha");
      let date = new Date();
      this.realtime.next(date);
    }, 1000);
  }

  /**
   * Finalizacion de intervalo de fecha y de fecha.
   */
  clearRealTime() {
    try { if (this.interval) { clearInterval(this.interval) } } catch (error) { console.log("eus2"); }
    this.realtime.next(null);
  }

  /**********************************/
  /***** CONTROL DE AVISOS *********/
  /********************************/

  /**
   * 
   * @param message Texto a mostrar
   * @param time tiempo de aparición de la notificación, milisegundos
   * @param position "top" / "bottom" / "middle"
   */
  async mostrarToast(message, time, position) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: time,
      position: position,
      cssClass: 'toast-scheme'
    });
    toast.present();
  }

  /**
   * Sistema de muestra de alertas.
   * @param type tipo de alerta para mostrar una imagen u otra 1 aviso - 2 error - 3 confirmacion
   * @param message contenido a mostrar, no se permiten iconos fa-icons
   * @param confirmString texto de botón confirmar 
   * @param cancelString texto opcional de botón cancelar
   * @returns promesa proveniente de función alert.onDidDismiss()
   */
  async showAlert(type: number, message: string, confirmString: string, cancelString?: string) {
    let imagen = type == 1 ? "alert" : type == 2 ? "error" : type == 3 ? "confirmacion" : "";
    let customButtons = [{
      text: confirmString,
      cssClass: "alert-btn confirmarButton",
      handler: () => { alert.dismiss(true) }
    }];
    let finalMessage = '<div class="alertImageContainer vertical"><img style="color:yellow" src="assets/img/' + imagen + '.svg" class="alertImage"><br><label class="mensaje"> ' + message + '</label></div>';
    if (cancelString) {
      customButtons.unshift({
        text: cancelString,
        cssClass: "alert-btn cancelarButton",
        handler: () => { alert.dismiss(false) }
      })
    }
    const alert = await this.alertCtrl.create({
      message: finalMessage,
      cssClass: 'custom-alert-class alert-center-btns',
      backdropDismiss: false,
      buttons: customButtons,
    });
    await alert.present();
    return await alert.onDidDismiss();
  }
  
}
