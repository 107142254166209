import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'subasta', canActivate: [AuthguardService], loadChildren: () => import('./subasta/subasta.module').then(m => m.SubastaPageModule) },
  { path: 'listado-subastas', canActivate: [AuthguardService], loadChildren: () => import('./listado-subastas/listado-subastas.module').then(m => m.ListadoSubastasPageModule) },
  { path: 'compras', canActivate: [AuthguardService], loadChildren: () => import('./compras/compras.module').then(m => m.ComprasPageModule) },
  { path: 'listado-ventas', canActivate: [AuthguardService], loadChildren: () => import('./listado-ventas/listado-ventas.module').then(m => m.ListadoVentasPageModule) },
  { path: 'recupera-contrasenha', loadChildren: () => import('./recupera-contrasenha/recupera-contrasenha.module').then(m => m.RecuperaContrasenhaPageModule) },
  { path: 'app-update', canActivate: [AuthguardService], loadChildren: () => import('./app-update/app-update.module').then(m => m.AppUpdatePageModule) },
  { path: 'oferta', canActivate: [AuthguardService], loadChildren: () => import('./oferta/oferta.module').then(m => m.OfertaPageModule) },
  { path: 'cambio-contrasenha', canActivate: [AuthguardService], loadChildren: () => import('./cambio-contrasenha/cambio-contrasenha.module').then(m => m.CambioContrasenhaPageModule) },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
