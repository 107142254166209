import * as MQTT from 'mqtt';
import { Injectable } from '@angular/core';
// import { MqttClient } from 'mqtt'; //old
import { MqttClient } from "mqtt/dist/mqtt"; //new
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenData } from './oauth2-service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { ModalController } from '@ionic/angular';
import { SelectorPujaComponent } from '../modal/selector-puja/selector-puja.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { UtilService } from './util.service';


@Injectable({
  providedIn: 'root'
})

export class MqttService {
  private sendTime = 0;
  private receiveTime = 0;
  public timeDifference = 0;
  client: MqttClient;
  userId;
  userName;
  mensaje = new BehaviorSubject({});
  soundFile = "assets/data/mp3/beep.mp3";
  modalCantidad;

  constructor(
    public authService: TokenData,
    public vibration: Vibration,
    public nativeAudio: NativeAudio,
    public modalCtrl: ModalController,
    public storage: Storage,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public utilService: UtilService,
  ) { }


  conexion(id, nombre) {
    if (this.client) { return; }
    this.userId = id;
    this.userName = nombre;
    this.client = this.mqttConnect(id, nombre);
    this.addEventListeners();
  }

  mqttConnect(id, nombre) {
    return MQTT.connect(
      {
        protocolId: "MQTT",
        username: localStorage.getItem("mqttToken"),
        password: environment.password,
        hostname: environment.mqttbroker,
        port: environment.mqttport,
        keepalive: 20,
        // wsOptions: { 'secureProtocol': 'TLS_method', 'minVersion': 'TLSv1', 'maxVersion': 'TLSv1.3' },
        protocol: environment.mqttprotocol == "ws" ? "ws" : "wss",
        //protocolVersion: 5,
        will: { retain: false, topic: 'alive/' + id, payload: '{"event":"dc","clien":"' + id + '","codS":"dc","nombre":"' + nombre + '"}', qos: 1 }
      });
  }

  private addEventListeners(): void {
    this.client.addListener('offline', this.offline);
    this.client.addListener('disconnect', this.onDisconnect);
    this.client.addListener('reconnect', this.onReconnect);
    this.client.addListener('connect', this.onConnect);
    this.client.addListener('message', this.onMessage);
    this.client.addListener('error', this.onLog);
    this.client.addListener('packetsend', this.onPacketSend);
    this.client.addListener('packetreceive', this.onPacketReceive);
    this.client.addListener("tlsClientError", this.onLog);
  }

  private onPacketSend = (a) => {
    if (a.cmd == 'pingreq') {
      this.sendTime = new Date().getTime();
    }
  };

  // Add a new method to handle the packetreceive event
  private onPacketReceive = (a) => {
    if (a.cmd == 'pingresp') {
      this.receiveTime = new Date().getTime();
      this.timeDifference = this.receiveTime - this.sendTime;
    }
  };

  offline() {
    console.log("listener cliente mqtt conexion offline");
  }

  private onConnect: () => any = () => {
    this.client.subscribe("alive/" + this.userId);
    this.client.publish("alive/" + this.userId, '{"event":"saludo","client":' + this.userId + '}');
    this.client.subscribe("subasta");

    // Si el usuario se encuentra en el panel de subasta y es desconectado/reconectado del mqtt se requiere avisar al subastado
    if (this.router.url.includes("/subasta")) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params?.code) {
          this.publicar('alive/' + this.userId, '{"event":"conn","clien":"' + this.userId + '","nombre":"' + this.userName + '","codS":"' + params.code + '"}'
          );
        }
      });
    }

  };

  private onReconnect: () => any = () => {
    if (!this.client.connected) { return; }
    this.client.publish("alive/" + this.userId, '{"event":"saludo","client":' + this.userId + '}');

    // Si el usuario se encuentra en el panel de subasta y es desconectado/reconectado del mqtt se requiere avisar al subastado
    if (this.router.url.includes("/subasta")) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params?.code) {
          this.publicar('alive/' + this.userId, '{"event":"conn","clien":"' + this.userId + '","nombre":"' + this.userName + '","codS":"' + params.code + '"}'
          );
        }
      });
    }
  };

  private onDisconnect: () => any = () => {
    console.log("desconexion mqtt");
  };

  public desconectar() {
    if (this.client) {
      this.client.publish("alive/" + this.userId, '{"event":"dc","clien":"' + this.userId + '","codS":"dc"}');
      this.client?.end();
      this.client = null;
      this.userId = null;
      this.userName = null;
    }
  }

  //Listener de mensajes entrantes
  private onMessage = (topicUrl: string, message: Uint8Array) => {
    console.log(message.toString());
    if (message.toString() != '') {
      try {
        let data = JSON.parse(message.toString());
        this.mensaje.next(data);
        // if (topicUrl == `alive/${this.idClient}`  && payload.event == 'block' && payload.client == this.idClient ) {
        if (data.event == 'block') {
          // Desconectar al cliente si el topic corresponde a blocked y la id del cliente
          this.desconectar();
          this.mensaje.next("");
          this.router.navigateByUrl('/login?blocked=true', { replaceUrl: true });
          this.storage.remove("user");
        }
        switch (data["event"]) {
          case 'pc':
            try {
              this.vibration.vibrate(1000);
              this.nativeAudio.preloadSimple('sonido1', this.soundFile).then(
                (data) => { this.nativeAudio.play('sonido1').then((data) => { }), (error) => { } },
                (err) => { console.log(err); }
              );
            } catch (error) { console.log("Error en el evento pc de vibracion/sonido. ", error); }
            let cantidad = data["kgs"];
            let especie = data["esp"];
            let unidad = data["unid"];
            let precio = data["precio"];
            let divisible = data["divisible"];
            let timer = data['timer'];
            let codigo = data["codigo"];
            let proveedor = data["pro"];
            let tpf = data["tpf"];
            let puerto = data["puerto"];
            let lista = data["lista"];
            let limite = data["limite"];
            let codSubasta = data["codS"];
            this.modalSelectorPartidas({ unidad, especie, cantidad, precio, divisible, timer, codigo, proveedor, tpf, puerto, lista, limite }, codSubasta);
            break;
          case "aviso":
            try {
              if (data['tipo'] == "tostada") {
                this.utilService.mostrarToast(data["message"], data["time"], data["position"]);
              }
              if (data["tipo"] == "alerta") {
                let cancelString = data["cancelString"] ? data["cancelString"] : null // <- esto es opcional, si no viene nada en cancel solo se mostrara un boton
                this.utilService.showAlert(data["tipo-alerta"], data["message"], data["confirmString"], cancelString)
              }
              if (data["vibrar"] == true) {
                this.vibration.vibrate(data["vibracion"]);
              }
              if (data["sonido"] == true) {
                this.nativeAudio.preloadSimple('sonido1', this.soundFile).then(
                  (data) => { this.nativeAudio.play('sonido1').then((data) => { }), (error) => { } },
                  (err) => { console.log(err); }
                );
              }
              if (data["TTS"] == true) {
                // PLUGIN TTS SI ES APP MOVIL
                //SpeechSynthesisUtterance SI ES WEB
              }
            } catch (error) {
              console.log("ERROR EN MENSAJE DE AVISO: ", error);
            }
            break;
          case "cancel":
            if (data['id'] == this.userId) {
              this.utilService.mostrarToast('Compra Rechazada.', 2000, "bottom");
              try { this.modalCantidad.dismiss(); }
              catch (error) { console.log("Error de cierre de modal por evento de cancelacion. ", error); }
              this.modalCantidad = null;
            }
            break;
        }
      } catch (error) {
        console.log("error de mensaje json.parse", error);
      }
    }
  }

  private onLog = (error) => {
    console.log("mqtt error", error);
    console.log("onlog cliente: ", this.client);
  }

  //SUSCRIPCION
  public suscripcion(topic) {
    this.client.subscribe(topic);
  }

  //DESUSCRIBIR
  public desuscribir(topic) {
    this.client.unsubscribe(topic);
  }

  //publish
  public publicar(topic, mensaje) {
    // if (!this.client.connected || this.client.reconnecting) { return; }
    this.client.publish(topic, mensaje);
  }

  public estadoConexion() {
    return this.client.connected;
  }

  async modalSelectorPartidas(nvParam, cods) {
    if (this.modalCantidad) { return; }
    this.send('{"event":"recibido", "codC":"' + this.userId + '", "user":"' + this.userName + '"}', cods);
    this.modalCantidad = await this.modalCtrl.create({ component: SelectorPujaComponent, componentProps: nvParam, backdropDismiss: false, cssClass: 'modal-selector-class' });
    await this.modalCantidad.present();
    if (!this.modalCantidad) { this.modalCantidad = null; return; }
    const dismiss = await this.modalCantidad.onDidDismiss();
    if (!dismiss?.data) { this.modalCantidad = null; return; } // control de cierre de modal no debido al usuario
    if (dismiss?.data.cliente != 0) {
      this.send('{"event":"adjPalet", "precio":' + nvParam.precio + ', "codC":"' + this.userId +
        '", "clienteAsociado":"' + dismiss.data.cliente + '", "partidas":' + JSON.stringify(dismiss.data.partidas) + '}', cods);
    } else {
      this.send('{"event":"cant", "cant":"' + parseFloat("0") + '", "codS": "' + cods + '", "codC":"' + this.userId +
        '", "user":"' + this.userName + '","asociado":' + dismiss.data.cliente + '}', cods);
    }
    this.modalCantidad = null;
  }

  send(msg, cods) {
    this.publicar("subasta/" + cods, msg);
  }
}
