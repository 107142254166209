import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginObjectService } from '../services/login-object.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { UtilService } from './util.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class TokenData {
  public accesToken: string;
  private userData;
  tiempoConfiguracion;
  public refreshToken: string;
  sub: Subscription;
  backgroundEnterTime: number;
  subResume: Subscription;
  constructor(
    public http: HttpClient,
    public loginObject: LoginObjectService,
    public bnIdle: BnNgIdleService,
    public router: Router,
    public storage: Storage,
    public platform: Platform,
    public utilService: UtilService,
    public storageService: StorageService,
  ) { }

  public login(params) {
    this.userData = params.loginObject;
    this.userData.pagina = 0;
    this.userData.version = environment.version;
    this.userData.dispositivo = this.storageService.isWeb ? "web" : "movil";
    let headers = JSON.parse('{"content-type":"application/json"}');
    return this.http.post<any>(environment.APIURL + '/login', this.userData, headers);
  }

  public tokenReset(params) {
    let username = params.loginObject.username;
    let email = params.loginObject.email;
    let tokenCaptcha = params.loginObject.captchaToken;
    let headers = JSON.parse('{"content-type":"application/json"}');
    let data = { username: username, email: email, tokenCaptcha: tokenCaptcha };
    return this.http.post<any>(environment.APIURL + '/generaTokenReset', data, { headers }).pipe(
      map(response => response.token),
      catchError(error => {
        console.error('Error al obtener el token:', error);
        return of(null);
      })
    );
  }

  public codigoResetPass() {
    const token = localStorage.getItem('tokenreset');
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token }).append('content-type', 'application/json');
    return this.http.post<any>(environment.APIURL + '/recuperaPassword', {}, { headers: headers });
  }

  public compruebaCodigoPass(codigo) {
    const token = localStorage.getItem('tokenreset');
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token }).append('content-type', 'application/json');
    return this.http.post<any>(environment.APIURL + '/compruebaCodigo', { codigo: codigo }, { headers: headers });
  }

  public compruebaContrasenhas(pass1, pass2) {
    const codigo = localStorage.getItem('codigo');
    const token = localStorage.getItem('tokenreset');
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token }).append('content-type', 'application/json');
    return this.http.post<any>(environment.APIURL + '/reseteaPassword', { passwd: pass1, passwd2: pass2, codigo: codigo }, { headers: headers });
  }

  public processData(data: any): any {
    this.tiempoConfiguracion = data.sessionTime;
    this.startTimer(data.sessionTime);
    this.loginObject.setUsername(data.usuario);
    this.loginObject.setRoles(data.roles);
    this.loginObject.setUserId(data.idUsuario);
    this.loginObject.setClientes(data.cliente);
    this.loginObject.setBloqueado(data.bloqueado);
    this.loginObject.setMostrarOFertas(data.mostrarOfertas);
    this.loginObject.setSessionTime(data.sessionTime);
    localStorage.setItem('token', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
    localStorage.setItem('mqttToken', data.mqtt_token);
    return this.loginObject.loginObject;
  }

  public checkTokenValidity(token): any {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token }).append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.get<any>(environment.APIURL + '/cambioToken', httpOptions);
  }

  public refreshTokens() {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('refreshToken') }).append('content-type', 'application/json');
    let httpOptions = { headers: headers };
    return this.http.post<any>(environment.APIURL + '/refresh', {}, httpOptions);
  }

  public startTimer(tiempo) {
    // reinicio temporizador 
    try { this.bnIdle.stopTimer(); } catch (error) { console.log("E22"); }
    try { this.sub.unsubscribe(); } catch (error) { console.log("E11."); }
    this.sub = this.bnIdle.startWatching(tiempo).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        try { this.bnIdle.stopTimer(); this.sub.unsubscribe(); } catch (error) { console.log("E33."); }
        this.storage.remove("user");
        this.router.navigate(["/login"]);
      }
    });
  }

  public stopTimer() {
    try { this.bnIdle.stopTimer(); }
    catch (error) { console.log("Error de intento de parada de temporizador de sesión."); }
  }

  /**
   * Detección de ida de segundo plano 
   */
  detectPause() {
    this.platform.pause.subscribe(() => {
      this.backgroundEnterTime = new Date().getTime(); // Guarda la hora actual cuando la aplicación entra en segundo plano
      this.stopTimer();
    }, (error) => {
      console.log(error);
    });
  }


  /**
   *  Detección de vuelta de segundo plano, si el titulo es "SUBASTA" al volver de segundo plano se redirige a la pantalla listado de subastas.
   * Si el tiempo pasado en segundo plano es superior al tiempo permitido de inactividad se redirige al login
   * @param titulo string correspondiente a la pagina actual
   */
  detectResume(titulo) {
    if (this.subResume) { this.subResume.unsubscribe(); }
    this.subResume = this.platform.resume.subscribe(() => {
      this.utilService.startRealtime();
      let currentTime = new Date().getTime();
      let timeSegundoPlano = (currentTime - this.backgroundEnterTime) / 1000; // Calcula el tiempo en segundo plano en segundos
      if (timeSegundoPlano > this.tiempoConfiguracion) {
        this.storage.remove("user");
        try {
          this.bnIdle.stopTimer();
          this.sub.unsubscribe();
        } catch (error) { console.log("error de eliminacion de timer 1"); }
        this.router.navigateByUrl('/login', { replaceUrl: true });
      } else {
        try {
          this.bnIdle.stopTimer();
          this.sub.unsubscribe();
        } catch (error) { console.log("error de eliminacion de timer 2"); }
        this.startTimer(this.tiempoConfiguracion);
        if (titulo == 'SUBASTA') {
          this.router.navigate(['listado-subastas']);
        }
      }

    }, (error) => {
      console.log(error);
    });
  }


}