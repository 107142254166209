import { Component, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { NavParams, Platform } from '@ionic/angular';
import * as Hammer from 'hammerjs';
import { ModalController, Gesture, GestureController } from '@ionic/angular';
import { formatNumber } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { User } from 'src/assets/data/interfaces/interfaces-service';
import { Storage } from '@ionic/storage-angular';
import { ApiService } from 'src/app/services/api.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { LoadingService } from 'src/app/services/loading.service';
import { UtilService } from 'src/app/services/util.service';
import { FontAwesomeService } from 'src/app/services/fontawesome.service';

@Component({
  selector: 'app-selector-puja',
  templateUrl: './selector-puja.component.html',
  styleUrls: ['./selector-puja.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '5px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class SelectorPujaComponent implements OnDestroy {
  // cantidad, des_exter, id, subasta
  partidas = [];
  partidasSeleccionadas = [];
  // partidasClientes = [];
  @ViewChild('filaSwipe', { static: false }) filaSwipe: ElementRef;
  // selectedClientes= [];
  clientesPartida = {}
  expandedElement = null;
  object: Object
  indiceActual = 0;
  showpartidaList: boolean = true;

  totalSeleccionado: number = 0;
  numSeleccionado: number = 0;

  textoSeleccion: any;
  multipleClienteCheckbox: boolean = false;
  especie;
  cantidad;

  unidad;
  precio;
  divisible;
  timerInterval;
  clientes = [];
  group = new FormGroup({
    peso: new FormControl(''),
    cliente: new FormControl(''),
    partidas: new FormControl(''),
  });
  timer: any;
  lista;
  limite; //control de permiso para meter mas cantidad o no de la ofrecida en la partida

  // part, usado para llamada de grupo de partidas
  codigo: any;
  proveedor; //nombre barco/proveedor
  tpf; //texto tpf
  puerto; //nombre del puerto

  importeTotal: number = 0;

  subscriptions: Subscription[] = [];

  focusedpartida: any;
  totalCantidad = 0
  totalImporte = 0
  user: User = {
    username: null,
    userId: null,
    clientes: null,
    roles: null,
  };

  // allowConfirmar: boolean = false;

  modalOpened: boolean = false;

  constructor(
    navParams: NavParams,
    public modalCtrl: ModalController,
    private apiService: ApiService,
    public platform: Platform,
    public storage: Storage,
    private element: ElementRef,
    private gestureCtrl: GestureController,
    public keyboard: Keyboard,
    public loadingService: LoadingService,
    public utilService: UtilService,
    public faService: FontAwesomeService
  ) {
    this.loadingService.present();
    this.codigo = navParams.get('codigo');
    this.proveedor = navParams.get('proveedor');
    this.tpf = navParams.get('tpf');
    this.puerto = navParams.get('puerto');
    this.especie = navParams.get('especie');
    this.group.controls['peso'].setValue(
      formatNumber(navParams.get('cantidad'), 'es-Es', '1.2-2')
    );
    this.unidad = navParams.get('unidad');
    this.precio = navParams.get('precio');
    this.divisible = navParams.get('divisible');
    this.timer = navParams.get('timer');
    this.lista = navParams.get('lista');
    this.limite = navParams.get('limite');
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.element.nativeElement,
      threshold: 15,
      gestureName: 'swipe',
      onMove: ev => this.swipeDer()
    }, true);
    this.storage.get('user').then((data) => {
      this.user.username = data.username;
      this.user.userId = data.userId;
      this.user.clientes = data.clientes;
      this.user.roles = data.roles;

      this.apiService.getclientes(this.user.userId).subscribe({
        next: (data) => {
          this.clientes = data;
          this.group.controls['cliente'].setValue(this.clientes[0]['id']);
          // this.clientes.forEach(cliente => this.selectedClientes.push(cliente.id));
          this.apiService.getpartidasList(this.lista).subscribe({
            next: (result) => {
              this.partidas = result;
              console.log('this.partidas :>> ', this.partidas);
              this.initializeCantidadSeleccionada();
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    });

    this.timerInterval = setInterval(() => {
      if (this.timer == 0) {
        clearInterval(this.timerInterval);
        try {
          if (this.modalOpened) {
            this.utilService.alertCtrl.dismiss();
          }
        } catch (error) {
          console.log('error controlado de cierre de alert 1', error);
        }
        this.modalCtrl.dismiss({ dismissed: true, cantidad: 0, cliente: 0 });
      }
      this.timer--;
    }, 1000);
    this.backButtonController();
  }

  ngOnInit() {
    this.group.get('cliente').disable();

  }


  swipeDer() {
    console.log('SWIPE DERECHA');
    // Execute your function here
  }

  swipeIzq() {
    console.log('SWIPE IZQUIERDA');
    // Execute your function here
  }


  // selectCliente(clienteId){
  //   if(this.isSelectedCliente(clienteId)){
  //     this.selectedClientes.splice(this.selectedClientes.indexOf(clienteId),1);
  //   }
  //   else{
  //     this.selectedClientes.push(clienteId);
  //   }
  //   this.group.controls['cliente'].setValue(this.selectedClientes[0]);
  // }

  ionViewWillLeave() {
    clearInterval(this.timerInterval);
    try {
      if (this.modalOpened) {
        this.utilService.alertCtrl.dismiss();
      }
    } catch (error) {
      console.log('error controlado de cierre de alert 2', error);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  todasPartidasVacias() {
    return this.partidas.every(partida => partida.cantidad_original <= 0 && !this.partidaInPartidaClientes(partida.idPartida));
  }
  changeMultipleCliente() {
    if (this.multipleClienteCheckbox) {
      this.multipleClienteCheckbox = false;
    } else if (!this.multipleClienteCheckbox) {
      this.multipleClienteCheckbox = true;
    }
  }
  initializeCantidadSeleccionada() {
    this.partidas.forEach((p) => {
      p.cantidad_original = p.cantidad;
      p.showDetails = false;
      // if (p.divisible == 1) {
      p.cantidad = 0;
      let aux = p.cantidad;
      p.cantidad_formateada = formatNumber(aux, 'es-Es', '1.2-2');
      // }
    });
    this.loadingService.dismiss();
  }
  partidaInPartidaClientes(partidaId) {
    const clienteData = this.clientesPartida[this.group.controls['cliente'].value];

    if (clienteData) {
      return clienteData.hasOwnProperty(partidaId);
    }

    return false;
  }

  clienteAnterior() {
    if (this.partidasSeleccionadas.length > 0) {
      this.partidasSeleccionadas.filter(ps => ps.cantidad > 0);
      const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
      let clonedPartidasSeleccionadas = deepClone(
        this.partidasSeleccionadas
      ).map((partida) => {
        partida.cliente = this.group.controls['cliente'].value;
        return partida;
      });
      this.clientesPartida[this.group.controls['cliente'].value] = clonedPartidasSeleccionadas.reduce((acc, cps) => {
        acc[cps.idPartida] = cps;
        return acc;
      }, {});
    }
    else if (this.partidasSeleccionadas.length == 0) {
      this.clientesPartida[this.group.controls['cliente'].value] = {};
    }
    this.partidas.forEach((partida) => {
      partida.cantidad_original =
        partida.cantidad_original - partida.cantidad;
    });
    this.indiceActual--;
    this.partidasSeleccionadas = [];
    this.group.controls['cliente'].setValue(
      this.clientes[this.indiceActual].id
    );
    const matchedPartidaCliente = this.clientesPartida[this.group.controls['cliente'].value];
    if (matchedPartidaCliente) {
      this.partidas.forEach((partida) => {
        if (this.clientesPartida[this.group.controls['cliente'].value][partida.idPartida]) {
          partida.cantidad_original += this.clientesPartida[this.group.controls['cliente'].value][partida.idPartida].cantidad;
          partida.cantidad = this.clientesPartida[this.group.controls['cliente'].value][partida.idPartida].cantidad;
          partida.cantidad_formateada = formatNumber(
            this.clientesPartida[this.group.controls['cliente'].value][partida.idPartida].cantidad,
            'es-ES',
            '1.2-2'
          );
        } else {
          partida.cantidad = 0;
          partida.cantidad_formateada = formatNumber(0, 'es-Es', '1.2-2');
        }
      });
      // this.clientesPartida[this.group.controls['cliente'].value] = {};
      this.partidasSeleccionadas = this.partidas.filter((p) =>
        matchedPartidaCliente.hasOwnProperty(p.idPartida)
      );

    }
    else {
      this.partidas.forEach((partida) => {
        partida.cantidad = 0;
        partida.cantidad_formateada = formatNumber(0, 'es-Es', '1.2-2');
      });
    }
    this.getTotal();
  }
  async confirmarCantidad(OK, dismiss = false) {
    if (OK) {
      if (this.group.controls['cliente'].value != '') {
        // if (this.totalSeleccionado > 0) {
        if (dismiss) {
          this.dismissModal(true);
        } else {
          this.dismissModal(false);
        }
        // this.mostrarAlerta("Confirmar compra de: <br><span class='cant'>" + this.totalSeleccionado + " " + this.unidad + "</span> a <span class='cant'>" + this.precio + " €/" + this.unidad + "</span>");
        // } else {
        //   this.utilService.mostrarToast("Selección no valida", 2000, "top");
        // }
      } else {
        this.utilService.mostrarToast('Introduzca Cliente', 2000, 'top');
      }
    } else {
      this.cantidad = 0;
      if (this.modalOpened) {
        return;
      }
      this.modalOpened = true;
      this.utilService
        .showAlert(1, '¿Cancelar compra?', 'SI', 'NO')
        .then((result) => {
          this.modalOpened = false;
          if (result?.data == true) {
            setTimeout(() => {
              this.modalCtrl.dismiss({
                dismissed: true,
                partidas: [],
                cliente: 0,
              });
            }, 10);
          }
        });
      // let alert = await this.alertCtrl.create({
      //   cssClass: 'custom-alert-class alert-center-btns',
      //   backdropDismiss: false,
      //   message: "¿Cancelar compra?",

      //   buttons: [{
      //     text: 'NO',
      //     role: 'cancel',
      //     cssClass: "alert-btn cancelarButton",
      //     handler: () => { alert.dismiss(); }
      //   }, {
      //     text: 'SI',
      //     cssClass: "alert-btn confirmarButton",
      //     handler: () => {
      //       alert.dismiss();
      //       setTimeout(() => {
      //         this.modalCtrl.dismiss({ dismissed: true, partidas: [], cliente: 0 });
      //       }, 10);
      //     }
      //   }]
      // });
      // await alert.present().then(() => { this.modalOpened = true; });
      // await alert.onDidDismiss().then(() => { this.modalOpened = false; });
    }
  }

  // async mostrarAlerta(message) {
  //   let finalMessage = '<div class="alertImageContainer vertical"><ion-icon class="icon"  name="cart"></ion-icon><br><label class="mensaje">' + message + '</label></div>';

  //   let alert = await this.alertCtrl.create({
  //     cssClass: 'custom-alert-class',
  //     backdropDismiss: false,
  //     message: finalMessage,

  //     buttons: [{
  //       text: 'Cancelar',
  //       role: 'cancel',
  //       cssClass: "cancelarButton",
  //       handler: () => { this.dismissModal(false); alert.dismiss(); }
  //     }, {
  //       text: 'Confirmar',
  //       cssClass: "confirmarButton",
  //       handler: () => { this.dismissModal(true); alert.dismiss(); }
  //     }]
  //   });
  //   await alert.present().then(() => { this.modalOpened = true; });
  //   await alert.onDidDismiss().then(() => { this.modalOpened = false; });
  // }

  // async mostrarConfirmacion(message, type) {
  //   let finalMessage;
  //   if (type == 'confirmar') {
  //     finalMessage = '<div class="alertImageContainer"><img src="../../assets/img/confirmacion.png" class="alertImage"><br>' + message + '</div>';
  //   } else {
  //     finalMessage = '<div class="alertImageContainer vertical"><ion-icon class="icon" name="alert"></ion-icon><br><label class="mensaje">' + message + '</label></div>';
  //   }
  //   const alert = await this.alertCtrl.create({
  //     cssClass: 'custom-alert-class alert-center-btns',
  //     message: finalMessage,
  //     buttons: [{
  //       text: 'Aceptar',
  //       cssClass: "confirmarButton",
  //       handler: () => { alert.dismiss(); }
  //     }]
  //   });
  //   await alert.present();
  //   await alert.onDidDismiss();
  // }
  public getClienteNombre(idCli) {
    return this.clientes.find((cliente) => cliente.id === idCli).nombre;
  }

  public dismissModal(exec) {
    let allow = true;
    if (exec) {
      const hasPartida = Object.values(this.clientesPartida).some(cliente => Object.keys(cliente).length > 0);
      if (
        this.group.controls['cliente'].value &&
        (hasPartida || this.partidasSeleccionadas.length > 0)
      ) {
        if (this.partidasSeleccionadas.length > 0) {
          const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

          let clonedPartidasSeleccionadas = deepClone(this.partidasSeleccionadas).reduce((acc, partida) => {
            partida.cliente = this.group.controls['cliente'].value;
            acc[partida.idPartida] = partida;
            return acc;
          }, {});

          this.clientesPartida[this.group.controls['cliente'].value] = clonedPartidasSeleccionadas;
        }
        else {
          this.clientesPartida[this.group.controls['cliente'].value] = {};
        }

        if (allow) {
          let partidasFinales = [];
          for (let cliente in this.clientesPartida) {
            for (let idPartida in this.clientesPartida[cliente]) {
              partidasFinales.push(this.clientesPartida[cliente][idPartida]);
            }
          }
          this.modalCtrl.dismiss({ dismissed: true, partidas: partidasFinales });
        } else {
          this.modalOpened = true;
          this.utilService
            .showAlert(
              1,
              'Cantidad no válida.',
              'Aceptar'
            )
            .then((result) => {
              this.modalOpened = false;
              return;
            });
        }
      } else {
        if (!hasPartida) {
          allow = false;
          this.utilService.showAlert(
            1,
            'No hay lineas selecionadas.',
            'Aceptar'
          );
          return;
        }
        // Return the full clientesPartida dictionary or adjust as needed
        this.modalCtrl.dismiss({
          dismissed: true,
          partidas: this.clientesPartida,
        });
      }
    } else {
      if (
        this.group.controls['cliente'].value
      ) {
        if (this.partidasSeleccionadas.length > 0) {
          this.partidasSeleccionadas.forEach((element) => {
            // delete element.des_exter;
            if (element.cantidad <= 0) {
              allow = false;
              return;
              // this.partidasSeleccionadas.splice(this.partidasSeleccionadas.indexOf(element), 1);
            }
          });
        }
        if (allow) {
          if (this.partidasSeleccionadas.length > 0) {
            this.partidasSeleccionadas.filter(ps => ps.cantidad > 0);
            const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
            let clonedPartidasSeleccionadas = deepClone(
              this.partidasSeleccionadas
            ).map((partida) => {
              partida.cliente = this.group.controls['cliente'].value;
              return partida;
            });

            this.clientesPartida[this.group.controls['cliente'].value] = clonedPartidasSeleccionadas.reduce((acc, cps) => {
              acc[cps.idPartida] = cps;
              return acc;
            }, {});
          }
          else if (this.partidasSeleccionadas.length == 0) {
            this.clientesPartida[this.group.controls['cliente'].value] = {};
          }
          this.siguienteCliente();
          // this.partidasSeleccionadas = [];
          // this.modalCtrl.dismiss({ dismissed: true, partidas: this.partidasSeleccionadas, cliente: this.group.controls['cliente'].value });
        } else {
          this.modalOpened = true;
          this.utilService
            .showAlert(
              1,
              'Cantidad no válida.',
              'Aceptar'
            )
            .then((result) => {
              this.modalOpened = false;
              return;
            });
          // this.mostrarConfirmacion("Cantidad no válida en línea seleccionada.", "alerta");
        }
      } else {
        this.siguienteCliente();
      }
    }
  }

  getTotal() {
    this.totalSeleccionado = 0;
    this.importeTotal = 0;
    this.partidasSeleccionadas.forEach((p) => {
      this.totalSeleccionado += p.cantidad ? parseFloat(p.cantidad) : 0;
      this.importeTotal = this.totalSeleccionado * this.precio;
      // this.allowConfirmar = p.cantidad > 0 ? true : false;
    });
    // if (this.partidasSeleccionadas.length == 0) { this.allowConfirmar = false; }
  }

  todoSeleccionado() {
    return this.partidasSeleccionadas.length == this.partidas.filter(p => p.cantidad_original + p.cantidad > 0).length
  }

  selectAll() {
    if (this.todoSeleccionado()) {
      this.partidas.filter(p => p.cantidad_original + p.cantidad > 0).forEach((p) => {

        this.totalCantidad= this.totalCantidad - p.cantidad
        this.totalImporte= this.totalImporte - (p.cantidad * this.precio)
        
        p.cantidad = 0;
        p.cantidad_formateada = formatNumber(0, 'es-Es', '1.2-2');
      });
      this.partidasSeleccionadas = [];
    } else {
      this.partidasSeleccionadas = [];
      this.partidas.forEach((p) => {
        if (p.cantidad_original > 0) {
          this.totalCantidad= this.totalCantidad + p.cantidad_original
          this.totalImporte= this.totalImporte + (p.cantidad_original * this.precio)
          p.cantidad = p.cantidad_original;
          p.cantidad_formateada = formatNumber(p.cantidad, 'es-Es', '1.2-2');
        }
        if (p.cantidad > 0) {
          this.partidasSeleccionadas.push(p);
        }
      });
    }
    this.getTotal();
  }
  noQuedanPartidas() {
    return this.partidas.every(
      (partida) =>
        partida.cantidad_original == 0 ||
        this.partidasSeleccionadas.some(
          (partidaSel) =>
            partidaSel.idPartida == partida.idPartida &&
            partidaSel.cantidad >= partida.cantidad_original
        )
    );
  }
  partidaClienteConIdPartida(idPartida) {
    const currentClienteValue = this.group.controls['cliente'].value;

    // Comprueba si existe una entrada en clientesPartida para ese cliente
    const matchedPartidas = this.clientesPartida[currentClienteValue];

    // Si existe devuelve la cantidad
    if (matchedPartidas && matchedPartidas[idPartida]) {
      return matchedPartidas[idPartida].cantidad;
    }

    return 0;
  }

  clienteAlgunaPartida() {
    const currentClienteValue = this.group.controls['cliente'].value;
    // Comprueba si existe una entrada en clientesPartida para ese cliente
    const matchedPartidas = this.clientesPartida[currentClienteValue];
    console.log(matchedPartidas)
    if (matchedPartidas && Object.keys(matchedPartidas).length > 0) {
      return true;
    }
    return false;
  }


  siguienteCliente() {
    // Si el cliente actual no es el último de la lista, selecciona el siguiente
    if (
      this.indiceActual >= 0 &&
      this.indiceActual < this.clientes.length - 1
    ) {
      const valorSiguiente = this.clientes[this.indiceActual + 1].id;
      this.group.controls['cliente'].setValue(valorSiguiente);
    }

    this.partidas.forEach((partida) => {
      partida.cantidad_original = partida.cantidad_original - partida.cantidad;
    });

    this.indiceActual++;
    this.partidasSeleccionadas = [];
    const matchedPartidaCliente = this.clientesPartida[this.group.controls['cliente'].value];


    if (matchedPartidaCliente) {
      this.partidas.forEach((partida) => {
        const currentPartida = matchedPartidaCliente[partida.idPartida];

        if (currentPartida) {
          partida.cantidad = currentPartida.cantidad;
          partida.cantidad_original += this.clientesPartida[this.group.controls['cliente'].value][partida.idPartida].cantidad;
          partida.cantidad_formateada = formatNumber(
            currentPartida.cantidad,
            'es-ES',
            '1.2-2'
          );
        } else {
          partida.cantidad = 0;
          partida.cantidad_formateada = formatNumber(0, 'es-Es', '1.2-2');
        }
      });

      this.partidasSeleccionadas = this.partidas.filter((p) =>
        Object.keys(matchedPartidaCliente).includes(p.idPartida.toString())
      );
    }
    else {
      this.partidas.forEach((partida) => {
        partida.cantidad = 0;
        partida.cantidad_formateada = formatNumber(0, 'es-Es', '1.2-2');
      });
    }
    this.getTotal();
  }

  selectLine(partida) {
    if (
      this.partidasSeleccionadas.find((p) => p.idPartida == partida.idPartida)
    ) {

      this.totalCantidad= this.totalCantidad - partida.cantidad
      this.totalImporte= this.totalImporte - (partida.cantidad * this.precio)

      partida.cantidad = 0;
      partida.cantidad_formateada = formatNumber(partida.cantidad, 'es-Es', '1.2-2');
      this.partidasSeleccionadas = this.partidasSeleccionadas.filter((p) => {
        return p.idPartida != partida.idPartida;
      });
      
    } else {
      
      this.totalCantidad= this.totalCantidad + partida.cantidad_original
      this.totalImporte= this.totalImporte + (partida.cantidad_original * this.precio)
      
      partida.cantidad = partida.cantidad_original;
      let aux = partida.cantidad;
      partida.cantidad_formateada = formatNumber(aux, 'es-Es', '1.2-2');
      this.partidasSeleccionadas.push(partida);
    }
    this.getTotal();
  }

  partidaInSelected(partida) {
    return (
      this.partidasSeleccionadas.find(
        (p) => p.idPartida == partida.idPartida
      ) != null
    );
  }

  /**
   * Control de botón back del dispositivo.
   * Se evita que retroceda en el historial de la app y se avisa de cancelación de compra.
   */
  backButtonController() {
    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(9999, async () => {
        this.confirmarCantidad(false);
        return;
      })
    );
  }

  /****************************/
  /**** CONTROL DE INPUTS ****/
  /**************************/

  numberOnlyValidation($event: any, partida) {
    const pattern = /[0-9.,]/;
    let inputChar = $event.key;
    if (inputChar == 'Unidentified' || inputChar == 'Backspace') {
      return;
    }
    // Evita el nuevo caracter si no es un numero
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
      return;
    }
    // Evita el nuevo caracter si el resultado es mayor a la cantidad posible
    if (this.limite != 1) {
      if (+($event.target.value + $event.key) > partida.cantidad_original) {
        $event.preventDefault();
      }
    }
    // Evita mas de dos decimales
    if (($event.target.value + $event.key).toString().includes('.')) {
      if (
        ($event.target.value + $event.key).toString().split('.')[1].length > 2
      ) {
        $event.preventDefault();
      }
    }
    if (($event.target.value + $event.key).toString().includes(',')) {
      if (
        ($event.target.value + $event.key).toString().split(',')[1].length > 2
      ) {
        $event.preventDefault();
      }
    }
  }

  onChange($event) {
    if (
      $event.target.value.toString().length == 1 &&
      $event.target.value.toString().includes('.')
    ) {
      $event.target.value = '0.';
    }
    if (
      $event.target.value.toString().length == 1 &&
      $event.target.value.toString().includes(',')
    ) {
      $event.target.value = '0,';
    }
    if (
      $event.target.value.toString().includes(',') &&
      $event.target.value.toString().includes('.')
    ) {
      if (
        $event.target.value.toString().substr()[
        $event.target.value.toString().length - 1
        ] == ',' ||
        $event.target.value.toString().substr()[
        $event.target.value.toString().length - 1
        ] == '.'
      ) {
        $event.target.value = $event.target.value.slice(0, -1);
      }
    }
    if ($event.target.value.toString().includes(' ')) {
      $event.target.value = $event.target.value.replace(/\s/g, '');
    }
    if ($event.target.value.toString().includes('-')) {
      $event.target.value = $event.target.value.replace('-', '');
    }
    if ($event.target.value.toString().includes(',')) {
      if ($event.target.value.toString().split(',').length > 2) {
        $event.target.value = $event.target.value
          .toString()
          .replace(/,\s*$/, '');
      }
    }
    if ($event.target.value.toString().includes('.')) {
      if ($event.target.value.toString().split('.').length > 2) {
        $event.target.value = $event.target.value
          .toString()
          .replace(/.\s*$/, '');
      }
    }
  }

  /**
   * Limpieza de input al entrar en el foco.
   * @param $event
   * @param partida
   */
  updateFocus($event?, partida?) {
    //partida.cantidad = '';
    //partida.cantidad_formateada = '';
    $event.target.value = '';
  }

  /**
   * Control de salida de foco de input, si no se ha introducido ningún valor se reinicia la cantidad seleccionada a la original de la linea
   * @param $event
   * @param partida
   */
  updateBlur($event?, partida?) {
    if ($event.target.value == '') {
      $event.target.value = 0;
    }
    if (
      $event.target.value.toString().includes('.') &&
      $event.target.value.toString().includes(',')
    ) {
      $event.target.value = $event.target.value.replace('.', '');
      $event.target.value = $event.target.value.replace(',', '.');
    }
    if ($event.target.value.toString().includes(',')) {
      $event.target.value = $event.target.value.replace(',', '.');
    }
    console.log(partida)
    this.totalCantidad= this.totalCantidad - partida.cantidad
    this.totalImporte= this.totalImporte - (partida.cantidad * this.precio)

    partida.cantidad = +$event.target.value;
    console.log(partida.cantidad)

    partida.cantidad_formateada = formatNumber(
      +$event.target.value,
      'es-Es',
      '1.2-2'
    );
        this.totalCantidad= this.totalCantidad + partida.cantidad 
    this.totalImporte= this.totalImporte + (partida.cantidad  * this.precio)
    // if (partida.cantidad <= 0) { this.selectLine(partida); }
    this.getTotal();
  }

  togglePartidaDetails(partida) {
    this.partidas.forEach((p) => {
      if (p.idPartida != partida.idPartida) {
        p.showDetails = false;
      }
    });
    partida.showDetails = !partida.showDetails;
  }

  inputSelectLine(partida) {
    if (!this.partidaInSelected(partida)) {
      this.selectLine(partida);
    }
  }
}
