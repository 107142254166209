<ion-header>
  <ion-toolbar color="subasta-header">
    <ion-title
      style="height: 56px; font-family: 'Open Sans', sans-serif; color: white; font-weight: 600; font-size: 20px;">
      SELECCIONAR CANTIDAD
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid style="height: 100%;padding: 0; background-color: #7194a5; display: flex; flex-direction: column; ">

    <ion-row class="ion-text-left">
      <ion-col size="8" class="ion-text-left headers">
        <div class="datoCab verticalLeft">
          <div style="flex:0.1" class="verticalLeft">
            <fa-icon class="fontVh25" [icon]="faService.faShip"></fa-icon>
          </div>
          <div >
            <ion-label class="fontVh25 verticalLeft ">
              &nbsp; {{proveedor?.toUpperCase()}}
            </ion-label>
          </div>
        </div>
      </ion-col>
      <ion-col class="ion-text-left headers vertical">
        <div class="datoCab verticalRight">
          <div style="flex:0.2">
            <fa-icon style="font-size: 14px;" [icon]="faService.faIndustry"></fa-icon>
          </div>
          <div style="flex:2">
            <ion-label class="fontVh25 verticalLeft ">
              &nbsp; {{ puerto?.toUpperCase() }}
            </ion-label>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-left" style="padding-bottom: 1px;">
      <ion-col class="ion-text-left headers ion-no-padding" size="8">

        <ion-row>
          <ion-col  class="ion-no-padding">
            <div class="datoCab verticalLeft">
              <div style="flex:0.1" class="verticalLeft">
                <fa-icon class="fontVh25" [icon]="faService.faFish"></fa-icon>
              </div>
              <div>
                <ion-label class="fontVh25 verticalLeft ">
                  &nbsp; {{especie?.toUpperCase()}} &nbsp; {{tpf}}
                </ion-label>
              </div>
            </div>
          </ion-col>
          <!-- <ion-col style="padding-left: 0px;">
            <div class="datoCab verticalLeft">
              <div style="flex:0.25" class="verticalLeft">
                <ion-icon class=" fontVh25" name="pricetag"></ion-icon>
              </div>
              <div>
                <ion-label class="fontVh25 verticalLeft ">
                  &nbsp; {{tpf}}
                </ion-label>
              </div>
            </div>
          </ion-col> -->
        </ion-row>

        <ion-row style="padding-top: 1px;">
          <ion-col class="ion-no-padding">
            <div class="datoCab verticalRight">
              <div style="flex:0.25">
                <fa-icon class="fontVh25" [icon]="faService.faWeightHanging"></fa-icon>
              </div>
              <div style="flex:2">
                <ion-label class="fontVh25 verticalRight ">
                  {{ cantidad | number:'1.2-2':'es'}} {{unidad}}&nbsp;
                </ion-label>
              </div>
            </div>
          </ion-col>
        </ion-row>

      </ion-col>


      <ion-col size="4" class="ion-text-left headers" >
        <ion-row class="ion-text-left" style="height: 100%">
          <ion-col size="12" class="ion-text-left  ion-no-padding ion-no-padding">
            <div class="datoCab vertical"
              style="height: 100%; display: flex; flex-direction: column; justify-content: space-around; font-weight: 600;">
              <div style="flex:0.25;" class="fontVh25">
                €/{{unidad}}
              </div>
              <div>
                <ion-label class="vertical " style="font-size: 30px;">
                  {{precio | number:'1.2-2':'es'}}&nbsp;
                </ion-label>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="clientes?.length>1 " [ngClass]="totalSeleccionado==0 ? 'blanco': 'verde'">
      <ion-col size="1" class="ion-no-padding vertical">
        <fa-icon *ngIf="indiceActual > 0 " (click)="clienteAnterior()" style="font-size: 20px;color: #7194a5;"
          [icon]="faService.faArrowLeft"></fa-icon>
      </ion-col>
      <ion-col size="10" class="vertical ion-no-padding" #swipe>
        <form [formGroup]="group" style="width: 100%;">
          <mat-form-field [ngClass]="totalSeleccionado==0 ? 'blanco': 'verde'" style="width: 100%;">
            <mat-select formControlName="cliente" [disabled]="true">
              <mat-option *ngFor="let client of clientes" [value]="client.id">
                <!-- <mat-checkbox  [ngClass]="multipleClienteCheckbox ? '' : 'hideMultipleClientes'" [checked]="isSelectedCliente(client.id)" class="example-pseudo-checkbox"  (click)="$event.stopPropagation(); selectCliente(client.id)"class="inline-checkbox">
                </mat-checkbox> -->
                {{ client.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </form>

      </ion-col>
      <ion-col size="1" class="ion-no-padding vertical">
        <fa-icon *ngIf="indiceActual < clientes.length - 1" style="font-size: 20px;color: #7194a5;"
          [icon]="faService.faArrowRight" (click)="confirmarCantidad(true,false)"></fa-icon>
      </ion-col>
      <!-- <ion-col size="2" class="ion-no-padding verticalLeft">

        <ion-item lines="none" class="item-select radius-50"
          style="--ion-background-color: #f0f8ff">
          <ion-checkbox color="danger --bg-white" (ionChange)="changeMultipleCliente()" ></ion-checkbox>
        </ion-item>
      </ion-col> -->
    </ion-row>

    <ion-row class="table-head" style="font-size: 25px;">
      <ion-col size="8" class="ion-no-padding verticalLeft">
        <ion-item (ionChange)="selectAll()" lines="none" class="item-select "
          style="--ion-background-color: #3b3f51;color: white;">
          <ion-checkbox labelPlacement="end" color="danger" [checked]="todoSeleccionado() && !todasPartidasVacias()"
            [disabled]="todasPartidasVacias()"
            [ngClass]="partidasSeleccionadas.length != partidas.length ? '--bg-white' : ''">TODO</ion-checkbox>
        </ion-item>
      </ion-col>
      <ion-col style="font-size: 14px;color: white;">
        <ion-row>
          <ion-col class="verticalRight ion-no-padding">
            {{totalSeleccionado |number:'1.2-2':'es'}} KG
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="verticalRight ion-no-padding">
            {{importeTotal | number:'1.2-2':'es'}} €
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row class="partida-list" *ngIf="showpartidaList" [@detailExpand]="showpartidaList ? 'expanded' : 'collapsed'"
      [ngClass]="showpartidaList ? 'flex-grow-1' : ''">
      <ion-col size="12" class="ion-no-padding" style="width: 100%; overflow-y: scroll;">
        <ion-row class="vertical row-select" *ngIf="todasPartidasVacias()">
          <ion-col class="vertical">
            <ion-label style="font-size:14px"> No quedan partidas con cantidad disponible</ion-label>
          </ion-col>
        </ion-row>
        <ng-container *ngFor="let partida of partidas; let odd = odd;">

          <ion-row *ngIf="partida.cantidad_original > 0 || partidaInPartidaClientes(partida.idPartida)"
            [ngClass]="odd? 'odd' : '' " class="vertical row-select">
            <ion-col size="2" class="vertical ion-no-padding">
              <ion-item (ionChange)="selectLine(partida)" lines="none" class="radius-50" [ngClass]="odd? 'odd' : '' ">
                <ion-checkbox [ngClass]="!partidaInSelected(partida) ? '--bg-white' : ''" color="danger"
                  [checked]="partidasSeleccionadas.length == partidas.length || partidaInSelected(partida)"></ion-checkbox>
              </ion-item>
            </ion-col>
            <ion-col class="ion-no-padding">
              <ion-row style="width: 100%; font-size: 12px;">
                <ion-col size="12" class="ion-no-padding">

                  <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 16px; font-weight: 500;"
                    *ngIf="partida.tipo != '1'">
                    <ion-col size="" class="verticalLeft ion-no-padding">
                      <fa-icon class="vertical font-medium" [icon]="faService.faShip"></fa-icon>
                      <ion-label>{{partida.proveedor}}</ion-label>
                    </ion-col>
                    <ion-col (click)="togglePartidaDetails(partida)" class="verticalRight" size="2">
                      <fa-icon *ngIf="!partida.showDetails" [icon]="faService.faPlus"></fa-icon>
                      <fa-icon *ngIf="partida.showDetails" [icon]="faService.faMinus"></fa-icon>
                    </ion-col>
                  </ion-row>

                  <ion-row *ngIf="partida.tipo == '1'"
                    style="padding-top: 5px; padding-bottom: 5px; font-size: 14px; font-weight: 500;">
                    <ion-col size="" class="ion-no-padding verticalLeft">
                      <fa-icon class="vertical font-medium" style="font-size: 12px; margin-right: 5px;"
                        [icon]="faService.faBookmark"></fa-icon>
                      <ion-label>{{partida.des_exter}}</ion-label>
                    </ion-col>
                  </ion-row>

                  <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 15px; font-weight: 500;">
                    <ion-col size="12" class="ion-no-padding">
                      <ion-row>
                        <!-- <ion-col size="" class="ion-no-padding verticalRight" *ngIf="partida.divisible == 1" -->
                        <ion-col size="6" class="ion-no-padding verticalRight" style="border-radius: 7px;"
                          [ngClass]="partida.divisible != 1 ? 'input-grey' : partidaInSelected(partida) ? partida.cantidad > 0 ? 'input-green' : 'input-red' : 'input-grey'">
                          <fa-icon
                            style="position: absolute; z-index: 7777; left: 5px; color: black; margin-right: 5px; font-size: 11px;"
                            *ngIf="partida.divisible == 1" [icon]="faService.faPenToSquare"></fa-icon>
                          <ion-input label="" class="custom-input" inputmode="decimal" onPaste="return false;"
                            onDrag="return false;" onDrop="return false;" (click)="inputSelectLine(partida);"
                            [disabled]="partida.divisible == 0 || (partidaInSelected(partida) != true && partida.divisible == 1)"
                            (keydown)="numberOnlyValidation($event, partida)" (ionInput)="onChange($event)"
                            value="{{partida.cantidad_formateada}}" (ionFocus)="updateFocus($event, partida)"
                            (ionBlur)="updateBlur($event, partida)" (keyup.enter)="$event.target.blur()"></ion-input>
                          <ion-label
                            style="padding-left:5px; color: #717172; padding-right: 5px; font-size: 10px;">{{unidad}}</ion-label>
                        </ion-col>
                        <ion-col size="2" class="ion-no-padding vertical">
                          <ion-label>de</ion-label>
                        </ion-col>
                        <ion-col size="4" class="ion-no-padding verticalRight">
                          <ion-label style="padding-right: 5px;">
                            {{partida.cantidad_original |number:'1.2-2':'es'}}
                            <ion-label style="font-size: 10px;">{{unidad}}</ion-label>
                          </ion-label>
                        </ion-col>
                        <!-- <ion-col size="4" *ngIf="partidaInPartidaClientes(partida.idPartida) && this.clientes.length >1" class="ion-no-padding verticalRight">
                        <ion-label style="padding-right: 5px;">
                          {{partida.cantidad_original + partidaClienteConIdPartida(partida.idPartida)  |number:'1.2-2':'es'}}
                          <ion-label style="font-size: 10px;">{{unidad}}</ion-label>
                        </ion-label>
                      </ion-col> -->
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>

            <!-- DETALLES DE PARTIDA -->
            <ion-col *ngIf="partida.showDetails == true" size="12" class="ion-no-padding"
              style="background-color: #b7d9f7; color: #232222;">
              <ion-row>
                <ion-col size="0.5" class="ion-no-padding"></ion-col>
                <ion-col size="11" class="ion-no-padding">
                  <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 14px; font-weight: 500;">
                    <ion-col size="" class="ion-no-padding verticalLeft">
                      <fa-icon class="vertical font-medium" style="font-size: 12px; margin-right: 5px;"
                        [icon]="faService.faBookmark"></fa-icon>
                      <ion-label>{{partida.des_exter}}</ion-label>
                    </ion-col>
                  </ion-row>
                  <!--<ion-row>
                    <ion-col class="vertical">
                      <img [src]="partida.imagen">
                    </ion-col>
                  </ion-row>-->
                </ion-col>
                <ion-col size="0.5" class="ion-no-padding"></ion-col>
              </ion-row>
              <ion-row *ngIf="partida.observaciones?.length > 0">
                <ion-col size="0.5" class="ion-no-padding"></ion-col>
                <ion-col size="10" class="ion-no-padding">
                  <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 14px; font-weight: 500;">
                    <ion-col size="" class="ion-no-padding verticalLeft">
                      <fa-icon class="vertical font-medium" style="font-size: 12px; margin-right: 5px;"
                        [icon]="faService.faCircleInfo"></fa-icon>
                      <ion-label>{{partida.observaciones}}</ion-label>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-col>
    </ion-row>

    <ion-row class="table-info-pad table-foot" style="color:white">
      <ion-col size="6" class="ion-no-padding verticalLeft">
        <fa-icon class="fontVh25 vertical" [icon]="faService.faWeightHanging" style="margin-right: 5px;"></fa-icon>
        <ion-label>{{totalCantidad |number:'1.2-2':'es'}} KG</ion-label>
      </ion-col>
      <ion-col size="6" class="ion-no-padding verticalRight">
        <fa-icon class="fontVh25" [icon]="faService.faCoins" style="margin-right: 5px;"></fa-icon>
        <ion-label>{{totalImporte | number:'1.2-2':'es'}} €</ion-label>
      </ion-col>
    </ion-row>
    <!-- FIN SELECCIONAR PARTIDAS -->
    <!-- INICIO CONTENEDORES CLIENTES -->
    <!-- <ng-container *ngFor="let clientePartidas of partidasClientes">
    <ion-row class="table-head" style="font-size: 25px;">
      <ion-col size="2" class="ion-no-padding vertical" (click)="showpartidaList = !showpartidaList">
        <fa-icon *ngIf="!showpartidaList" class="vertical" [icon]="faService.faCaretDown"></fa-icon>
        <fa-icon *ngIf="showpartidaList" class="vertical" [icon]="faService.faCaretUp"></fa-icon>
      </ion-col>

      <ion-col size="10" class="verticalRight">
        <ion-label class="verticalLeft select-text">
          {{getClienteNombre(clientePartidas.cliente)}}
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row class="partida-list" *ngIf="showpartidaList" [@detailExpand]="showpartidaList ? 'expanded' : 'collapsed'"
      [ngClass]="showpartidaList ? 'flex-grow-1' : ''">
      <ion-col size="12" class="ion-no-padding" style="width: 100%; overflow-y: scroll;">
        <ion-row *ngFor="let partidaCli of clientePartidas.partidas;let odd = odd;" [ngClass]="odd? 'odd' : '' "
          class="vertical row-select">
          <ion-col size="1" class="ion-no-padding"></ion-col>
          <ion-col class="ion-no-padding">
            <ion-row style="width: 100%; font-size: 12px;">
              <ion-col size="12" class="ion-no-padding">

                <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 16px; font-weight: 500;"
                  *ngIf="partidaCli.tipo != '1'">
                  <ion-col size="6" class="verticalLeft ion-no-padding">
                    <fa-icon class="vertical font-medium" [icon]="faService.faShip"></fa-icon>
                    <ion-label>{{partidaCli.proveedor}}</ion-label>
                  </ion-col>
                  <ion-col size="4" class="ion-no-padding verticalRight">
                    <ion-label style="padding-right: 5px;">
                      {{partidaCli.cantidad_original |number:'1.2-2':'es'}}
                      <ion-label style="font-size: 10px;">{{unidad}}</ion-label>
                    </ion-label>
                  </ion-col>
                  <ion-col (click)="togglePartidaDetails(partidaCli)" class="verticalRight" size="2">
                    <fa-icon *ngIf="!partidaCli.showDetails" [icon]="faService.faPlus"></fa-icon>
                    <fa-icon *ngIf="partidaCli.showDetails" [icon]="faService.faMinus"></fa-icon>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="partidaCli.tipo == '1'"
                  style="padding-top: 5px; padding-bottom: 5px; font-size: 14px; font-weight: 500;">
                  <ion-col size="" class="ion-no-padding verticalLeft">
                    <fa-icon class="vertical font-medium" style="font-size: 12px; margin-right: 5px;"
                      [icon]="faService.faBookmark"></fa-icon>
                    <ion-label>{{partidaCli.des_exter}}</ion-label>
                  </ion-col>
                </ion-row>

                <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 15px; font-weight: 500;">
                  <ion-col size="12" class="ion-no-padding">
                    <ion-row>

                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-col> -->

    <!-- DETALLES DE PARTIDA -->
    <!-- <ion-col *ngIf="partidaCli.showDetails == true" size="12" class="ion-no-padding"
            style="background-color: #badae3; color: #232222;">
            <ion-row>
              <ion-col size="0.5" class="ion-no-padding"></ion-col>
              <ion-col size="10" class="ion-no-padding">
                <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 14px; font-weight: 500;">
                  <ion-col size="" class="ion-no-padding verticalLeft">
                    <fa-icon class="vertical font-medium" style="font-size: 12px; margin-right: 5px;"
                      [icon]="faService.faBookmark"></fa-icon>
                    <ion-label>{{partidaCli.des_exter}}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="partidaCli.observaciones?.length > 0">
              <ion-col size="0.5" class="ion-no-padding"></ion-col>
              <ion-col size="10" class="ion-no-padding">
                <ion-row style="padding-top: 5px; padding-bottom: 5px; font-size: 14px; font-weight: 500;">
                  <ion-col size="" class="ion-no-padding verticalLeft">
                    <fa-icon class="vertical font-medium" style="font-size: 12px; margin-right: 5px;"
                      [icon]="faService.faCircleInfo"></fa-icon>
                    <ion-label>{{partidaCli.observaciones}}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row class="table-info-pad table-foot">
      <ion-col size="2" class="ion-no-padding verticalLeft" style="font-weight: 500;">
      </ion-col>
      <ion-col size="5" class="ion-no-padding verticalLeft">
        <fa-icon class="fontVh25 vertical" [icon]="faService.faWeightHanging" style="margin-right: 5px;"></fa-icon>
        <ion-label>{{clientePartidas.cantidadTotal |number:'1.2-2':'es'}} KG</ion-label>
      </ion-col>
      <ion-col size="5" class="ion-no-padding verticalRight">
        <fa-icon class="fontVh25" [icon]="faService.faCoins" style="margin-right: 5px;"></fa-icon>
        <ion-label>{{clientePartidas.precio | number:'1.2-2':'es'}} €</ion-label>
      </ion-col>
    </ion-row>
  </ng-container> -->
    <!-- FIN CONTENEDORES CLIENTES -->

    <ion-row style="align-items: flex-end;height: 8%;" [ngClass]="!showpartidaList ? 'flex-grow-1' : ''">
      <ion-col size="6" class="vertical colbg-cancelar ion-no-padding">
        <ion-button class="btns bg-cancelar spinner-color" (click)="confirmarCantidad(false)">
          <mat-spinner [diameter]="15" mode="indeterminate"></mat-spinner> &nbsp;{{timer}}"
        </ion-button>
      </ion-col>
      <!-- <ion-col size="6" class="vertical" *ngIf="indiceActual < clientes.length - 1 && !noQuedanPartidas()">
        <ion-button class="btns bg-anhadir" (click)="confirmarCantidad(true,false)" [disabled]="keyboard.isVisible">
          <fa-icon style="font-size: 14px;" [icon]="faService.faArrowRight"></fa-icon>
        </ion-button>
      </ion-col> -->
      <ion-col size="6" class="vertical colbg-confirmar ion-no-padding">
        <!-- <ion-col size="6" class="vertical" *ngIf=" noQuedanPartidas()"> -->
        <ion-button class="btns bg-confirmar" (click)="confirmarCantidad(true,true)" [disabled]="keyboard.isVisible">
          CONFIRMAR
        </ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
</ion-content>