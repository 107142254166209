import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { UtilService } from './services/util.service';
import { StorageService } from './services/storage.service';
import { FontAwesomeService } from './services/fontawesome.service';
import { register } from 'swiper/element/bundle';
import { MobileAccessibility } from '@awesome-cordova-plugins/mobile-accessibility';
import { ApiService } from './services/api.service';
import { Subscription } from 'rxjs';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  loggedIn = false;
  dark = false;
  version = environment.version
  appPages = [];

  customInteval: any;

  showOfertasMenu: boolean = false;
  menuLimit: boolean = false;

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private insomnia: Insomnia,
    public storage: Storage,
    public utilService: UtilService,
    public storageService: StorageService,
    public faService: FontAwesomeService,
    public apiService: ApiService,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.storageService.storage.create();
    this.utilService.startRealtime();

    this.routerEvent();
    this.checkDeviceSystem();
    this.getEspeciesUrl();

    this.appPages = [
      { title: 'Subastas en curso', url: '/listado-subastas', icon: this.faService.faBell, },
      { title: 'Ofertas', url: '/oferta', icon: this.faService.faTrendUp, },
      { title: 'Compras', url: '/compras', icon: this.faService.faBasket, },
      //{ title: 'Listado ventas', url: '/listado-ventas', icon: this.faService.faList, },
    ];
  }

  /**
   * Suscripción a evento de cambio de ruta. Usado para controlar el funcionamiento del menú lateral.
   * Aviso: el evento salta múltiples veces en cada cambio de ruta.
   */
  routerEvent() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd && (event.url === undefined || event.url === ('/') || event.url.includes('/login') || event.url.includes('/recupera-contrasenha') || event.url.includes('/app-update'))) {
        this.menu.enable(false);
      } else {
        if (event instanceof NavigationEnd && (event.url != undefined && event.url != ('/') && !event.url.includes('/login') && !event.url.includes('/recupera-contrasenha') && !event.url.includes('/app-update'))) {
          this.menu.enable(true);
          console.log("enable menu ", event.url);

        }
      }
      // @TODO meter esto en un evento final de navegacion ▼
      try {
        this.storage.get("user").then(data => {
          if (data && data.mostrarOfertas == true) { this.showOfertasMenu = true; }
          else { this.showOfertasMenu = false; }
          if (data && data.bloqueado?.includes("NOSUBASTA")) { this.menuLimit = true; }
          else { this.menuLimit = false; }
        });
      } catch (error) { console.log("Err::", error); }
      if (event instanceof NavigationEnd) {
        // @TODO limitar la visualizacion del menu, tanto el propio menu como las selecciones realizables si se esta bloqueado
      }
    });
  }

  /**
   * Comprobacion de si el dispositivo es web o móvil(android/ios)
   */
  checkDeviceSystem() {
    this.platform.ready().then(() => {
      MobileAccessibility.usePreferredTextZoom(false);
      if (this.platform.is("capacitor")) {
        this.storageService.isWeb = false;
        if (this.platform.is("ios") || this.platform.is("ipad") || this.platform.is("iphone")) { this.storageService.isIOS = true; }
        else { this.storageService.isIOS = false; }
      } else {
        this.storageService.isWeb = true;
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.insomnia.keepAwake().then(
        () => console.log('success'),
        () => console.log('error')
      );

    });
  }
  openPdf(){
    window.open(this.storageService.urlSpecies + 'politica.pdf','_system')
  }
  /**
   * Recepción de url en la que se encuentran las fotos de especies por fao. Estas imágenes se usan cuando un lote no tiene asociada una imagen.
   */
  async getEspeciesUrl() {
    let url = await this.storageService.getData("urlSpecies");
    if (!url) {
      let sub: Subscription = this.apiService.getFotosUrl().subscribe({
        next: (result) => { this.storageService.setUrlSpecies(result); },
        error: (error) => { console.log("Error en la recepción de url de especies. ", error); sub.unsubscribe(); },
        complete: () => { sub.unsubscribe(); }
      });
    } else { this.storageService.urlSpecies = url; }
  }
}
