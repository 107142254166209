<ion-content style="    --background: #7194a5">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h2>
          Filtros
        </h2>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <mat-form-field class="example-full-width">
          <mat-label style="color: white;">Seleccione una Fecha</mat-label>
          <input style="color: white;" matInput [matDatepicker]="picker" readonly (click)="picker.open()"
            (dateChange)="onBlur($event)" (keydown.enter)="picker.open()" [(ngModel)]="fecha" name="inputDatepicker">
          <mat-datepicker-toggle style="color: white;" matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker-toggle style="color: white;" matSuffix (click)="clear()">
            <mat-icon matDatepickerToggleIcon>X</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker style="color: white;" touchUi #picker></mat-datepicker>
        </mat-form-field>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <mat-form-field class="example-full-width" style="color: white;">
          <mat-label style="color: white;">Seleccione un Proveedor</mat-label>
          <input type="text" style="color: white;" matInput [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" style="color: white;"  [displayWith]="getProveedor.bind(this)">
            <mat-option *ngFor="let proveedor of filteredProveedores | async" [value]="proveedor.id">
              {{proveedor.nombre}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <mat-form-field class="example-full-width">
          <mat-label style="color: white;">Seleccione una Especie</mat-label>
          <input type="text" style="color: white;" matInput [formControl]="myControlEspecie"
            [matAutocomplete]="autoEspecies">
          <mat-autocomplete autoActiveFirstOption #autoEspecies="matAutocomplete" [displayWith]="getEspecie.bind(this)">
            <mat-option *ngFor="let especie of filteredEspecies | async" [value]="especie.registro.id" >
              {{especie.registro.nombre}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ion-col>
    </ion-row>
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-button style="--background: #35aa47;" (click)="save()">Confirmar </ion-button>
      </ion-col>
      <ion-col>
        <ion-button (click)="cerrar()" style="--background: #d84a38;">Anular</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>