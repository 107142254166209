import { DecimalPipe, formatNumber } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, startWith, map } from 'rxjs';
import { ApiService } from '../services/api.service';
import { FontAwesomeService } from '../services/fontawesome.service';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-cesion',
  templateUrl: './cesion.component.html',
  styleUrls: ['./cesion.component.scss'],
})
export class CesionComponent implements OnInit {
  @ViewChild('tab') tabGroup;
  datos: any;
  vendedurias: any;
  form: FormGroup;
  filterVendedurias: Observable<any>
  totalCedido = 0
  cesiones = []
  paginado = true;
  config
  cesionSelect = null;

  constructor(public modalCtrl: ModalController,data: NavParams, private formBuilder: FormBuilder, public iconsService: FontAwesomeService, private dataService: ApiService, private decimalPipe: DecimalPipe, private fb: FormBuilder) {
    this.datos = data.data
    console.log(this.iconsService.faAnchor)
    this.form = this.formBuilder.group({
      vendeduria: ["", Validators.required],
      cantidad: ["", Validators.required],
      observaciones: [""]

    });

  }

  select(cesion) {
    if (this.cesionSelect == cesion) {
      this.cesionSelect=null
    } else {
      this.cesionSelect = cesion
    }
  }

  displayFn(venderuria): string | undefined {
    return venderuria ? venderuria.nombre : undefined;
  }
  tabChange(el) {
    if (this.tabGroup.selectedIndex == 0 && this.paginado == false) {
      this.paginado = true
    } else {
      this.paginado = false
    }
  }
  ngOnInit() {
    this.getCesiones()
    this.dataService.getVendedurias().subscribe((data) => {
      this.vendedurias = data;
      this.filterVendedurias = this.form.controls['vendeduria'].valueChanges.pipe(
        startWith(""),
        map(val => {
          const name = typeof val === 'string' ? val : '';
          return this._filter(name as string)
        })
      );
    }, error => {
      console.log(error);
    })

  }
  private _filter(name: string) {
    return this.vendedurias.filter(option => option.nombre.toLowerCase().includes(name.toLowerCase()));
  }




  numberOnlyValidation(event: any) {
    const pattern = /[0-9.]/;
    let inputChar = event.key;

    if (inputChar == 'Unidentified' || inputChar == 'Backspace') {
      return
    }

    // Evita el nuevo caracter si no es un numero 
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return
    }

    // Evita el nuevo caracter si el resultado es mayor a la cantidad posible

    if (+(event.target.value + event.key) > (this.datos['cantidad'] - this.totalCedido)) {
      event.preventDefault();
    }
    if (Number.isNaN(+(event.target.value + event.key))) {
      if (event.key > (this.datos['cantidad'] - this.totalCedido)) {
        event.preventDefault();
      }
    }
  }

  onChange(e) {
    if (e.target.value.toString().length == 1 && e.target.value.toString().includes('.')) {
      e.target.value = '0.'
    }
    if (e.target.value.toString().length == 1 && e.target.value.toString().includes(',')) {
      e.target.value = '0,'
    }


    if (e.target.value.toString().includes(',') && e.target.value.toString().includes('.')) {
      if (e.target.value.toString().substr()[e.target.value.toString().length - 1] == ',' || e.target.value.toString().substr()[e.target.value.toString().length - 1] == '.') {
        e.target.value = e.target.value.slice(0, -1)
      }
    }


    if (e.target.value.toString().includes(' ')) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }

    if (e.target.value.toString().includes('-')) {
      e.target.value = e.target.value.replace('-', "");
    }
    if (e.target.value.toString().includes(',')) {
      if (e.target.value.toString().split(',').length > 2) {
        e.target.value = e.target.value.toString().replace(/,\s*$/, "");
      }
    }
    if (e.target.value.toString().includes('.')) {
      if (e.target.value.toString().split('.').length > 2) {
        e.target.value = e.target.value.toString().replace(/.\s*$/, "");
      }
    }

  }
  changeValue(event) {
    if (event.target.value.toString().includes('.') && event.target.value.toString().includes(',')) {
      event.target.value = event.target.value.replace('.', "");
      event.target.value = event.target.value.replace(',', ".");
    }

    if (event.target.value.toString().includes(',')) {
      event.target.value = event.target.value.replace(',', ".");
    }

    this.form.controls['cantidad'].setValue(formatNumber(event.target.value, "es-Es", "1.2-2"))
  }
  save() {
    let cantidad
    if (this.form.controls['cantidad'].value.includes('.') && this.form.controls['cantidad'].value.toString().includes(',')) {
      cantidad = this.form.controls['cantidad'].value;
      cantidad = cantidad.replace(',', ".");
    }

    if (this.form.controls['cantidad'].value.toString().includes(',')) {
      cantidad = this.form.controls['cantidad'].value.replace(',', ".");
    }
    console.log(this.datos)
    let data = [{
      "adjudicacion": this.datos.id,
      "vendeduria": this.form.controls['vendeduria'].value.id,
      "observaciones": this.form.controls['observaciones'].value,
      "cantidad": cantidad,
    }]
    this.dataService.insertCesion(data).subscribe(data => {
      this.getCesiones()
      this.form.patchValue({ vendeduria: "", cantidad: "", observaciones: "" })
    })
  }
  getCesiones() {
    this.dataService.getCesion(this.datos.id).subscribe((data) => {
      this.cesiones = data;
      this.totalCedido = 0
      this.cesiones.forEach(element => {
        this.totalCedido = this.totalCedido + element.cantidad
      })
    }, error => {

      console.log(error);
    })
  }

  deleteCesion(id) {
    this.dataService.deleteCesion({ "id": id }).subscribe((data) => {
      this.getCesiones()
    }, error => {
      console.log(error);
    })
  }

  cerrarModal() {
    this.modalCtrl.dismiss();
  }
}
