import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Información sobre si la app está en modo web o no. 
   * Se usa esta variable para mostrar botones con eventos touch o click y para búsqueda de versión en modo apk.
   */
  isWeb: boolean = false;
  isIOS: boolean = false;
  urlSpecies: string = "";

  constructor(
    public storage: Storage,
  ) { }

  setWeb(bool: boolean) {
    this.isWeb = bool;
    this.setData("isWeb", bool);
  }

  setIOS(bool: boolean) {
    this.isIOS = bool;
    this.setData("isIOS", bool);
  }

  setUrlSpecies(url: string) {
    this.urlSpecies = url;
    this.setData("urlSpecies", url);
  }

  setData(dataName, data) {
    return this.storage.set(dataName, data);
  }

  getData(dataName) {
    return this.storage.get(dataName);
  }

  removeData(dataName) {
    return this.storage.remove(dataName);
  }

}
