import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { startWith, map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss'],
})
export class FiltrosComponent implements OnInit {
  myControl = new FormControl('');
  myControlEspecie = new FormControl('');
  especies = []
  proveedores = []
  fecha = "";
  filteredEspecies: any;
  filteredProveedores: any;
  fechaSend = "all";

  constructor(public apiService: ApiService, private matdialog: MatDialogRef<any>) {

  }

  ngOnInit() {
    this.getProveedores()
    this.getEspecies()
  }

  onBlur(event) {
    this.fecha = event.value;
    this.fechaSend = event.value.date() + "-" + this.fillLeft(String(event.value.month() + 1)) + "-" + event.value.year();
  }
  clear() {
    this.fecha = "";
    this.fechaSend = "all";
  }

  fillLeft(text) {
    while (text.length < 2) {
      text = "0" + text;
    }
    return text;
  }

  getProveedores() {
    this.apiService.getProveedores().subscribe((data: any) => {
      this.proveedores = data
      this.filteredProveedores = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    })
  }
  getEspecie(Id: string) {
    return this.especies.find(especie => especie.registro.id === Id).registro.nombre;
  }
  getProveedor(Id: string) {
    return this.proveedores.find(proveedor => proveedor.id === Id).nombre;
  }
  getEspecies() {
    this.apiService.getEspecies().subscribe((data: any) => {
      this.especies = data
      this.filteredEspecies = this.myControlEspecie.valueChanges.pipe(
        startWith(''),
        map(value => this._filterEspecie(value || '')),
      );
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.proveedores.filter(option => option["nombre"].toLowerCase().includes(filterValue));
  }

  private _filterEspecie(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.especies.filter(option => option["registro"]["nombre"].toLowerCase().includes(filterValue));
  }
  cerrar() {
    this.matdialog.close()
  }
  save() {
    let proveedorSend = "all"
    let especieSend = "all"
    if (this.myControlEspecie.value != '') {
      especieSend = this.myControlEspecie.value
    }

    if (this.myControl.value != '') {
      proveedorSend = this.myControl.value
    }


    this.matdialog.close({ "fecha": this.fechaSend, "especie": especieSend, "proveedor": proveedorSend })
  }
}
