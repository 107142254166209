import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { TokenData } from './oauth2-service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private router: Router,
    private inj: Injector,
    public oauth: TokenData
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');
    let request = req;
    if (token && !request.url.includes("refresh") && !request.url.includes("recuperaPassword")
      && !request.url.includes("compruebaCodigo") && !request.url.includes("generaTokenReset")
      && !request.url.includes("reseteaPassword") && !request.url.includes("getFotosUrl")) { request = this.addToken(request, token); }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!request.url.includes("refresh") && err.status === 401) {
          return this.handle401Error(request, next);
        } else if (request.url.includes("refresh")) {
          this.oauth.stopTimer();
          this.router.navigate(['/login'])
        }
        return throwError(err);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.oauth.refreshTokens().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token['access_token']);
          localStorage.setItem('token', token['access_token']);
          return next.handle(this.addToken(request, token['access_token']));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    // Para evitar cambiar todas las llamadas para que los consignatarios puedan tener mas de un puerto se añade el puerto a filtrar a traves del interceptor
    // y la api filtrara a traves de el
    return request.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } });
  }
}